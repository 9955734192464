import * as React from 'react';
import { Nav, INavLink, INavLinkGroup, FontIcon } from '@fluentui/react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useState } from "react";
import { isMobile } from 'react-device-detect';
import "../styles/LeftMenu.css";

interface IProps {
    links: INavLinkGroup[],
    selectedKey: string,
    onLinkClick: (key: string) => void
}

const LeftMenu: React.FC<IProps> = ({links, selectedKey, onLinkClick}) => {
    const appInsights = useAppInsightsContext();
    const [isVisible, setVisible] = useState(true);
    return (
        <div className="menu-container">
            <FontIcon iconName='GlobalNavButton' title='Collapse/Expand Menu' className="menu-chevron" onClick={() => setVisible(!isVisible)}/>
            {isVisible && <Nav selectedKey={selectedKey} ariaLabel="LefMenu" className="menu-items" groups={links} onLinkClick={
                    (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                        if (item && item.key) {
                            if(appInsights.isInitialized()) appInsights.trackEvent({name: "Left Menu Clicked", properties: {menuItem: item.key}});
                            if(isMobile) setVisible(false);
                            onLinkClick(item.key);
                        }
                    }}/>}
        </div>
    );
};

export default LeftMenu;