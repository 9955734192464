import { useState, useEffect } from "react";
import { useBoolean } from '@fluentui/react-hooks';
import { INavLinkGroup, Theme } from '@fluentui/react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';
import './styles/Settings.css'
import LeftMenu from './widgets/LeftMenu';
import BasePage from './widgets/BasePage';
import { callFunction, loadAppsData } from './lib/Helpers';
import { AppObject } from './widgets/AppCard';
import Subscriptions from './settingsPages/Subscriptions';
import Help from './settingsPages/Help';
import { Providers, ProviderState } from '@microsoft/mgt-react';

type EntObject = {
  apps: string[],
  subscriptions: string[],
}

type SettingsProps = {
  theme?: Theme,
  isDarkTheme?: boolean,
  hasToken?: boolean,
};

const Settings: React.FC<SettingsProps> = ({ theme, isDarkTheme, hasToken }) => {

  const [isSignedIn, setSignedIn] = useState<boolean>(Providers.globalProvider.state === ProviderState.SignedIn ? true : false);

  Providers.onProviderUpdated(() => {
    if (Providers.globalProvider.state === ProviderState.SignedIn) {
      setSignedIn(true)
    }
    else if (Providers.globalProvider.state === ProviderState.SignedOut) {
      setSignedIn(false)
    }
  })

  useEffect(() => {
    if (isSignedIn) {
      let provider = Providers.globalProvider;
      if (provider) {
        let graphClient = provider.graph.client;
        graphClient.api('me').get().then((value) => { setUserId(value.id); });
        graphClient.api('organization').get().then((value) => { SetTid(value.id); });
      }
    }

  }, [isSignedIn])

  const [tid, SetTid] = useState<string>("");
  const [userId, setUserId] = useState<string>("");

  const appInsights = useAppInsightsContext();

  const [userEntitlements, setUserEntitlements] = useState<string>();
  const [entObj, setEntObj] = useState<EntObject>();

  const [allApps, setAllApps] = useState<string>();
  const [appsObj, setAppsObj] = useState<AppObject[]>();

  const [isTrial, { setTrue: inTrial, setFalse: notInTrial }] = useBoolean(false);

  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const [tokenLoaded, setTokenLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (token && !tokenLoaded) {
      callFunction(true, "resolveSubscription?{0}&token=" + encodeURIComponent(token))
         .catch((error) => { alert("We couldn't identify this purchase. Please reopen this SaaS subscription in the Azure portal or in Microsoft 365 Admin Center and select Configure Account or Manage Account again."); if (appInsights) appInsights.trackException({ exception: error, properties: { component: 'App', location: 'onResolveSubscription' } }); })
         .then(()=>{ setTokenLoaded(true)});
    }
  }, [])

  useEffect(() => {
    if (userId) {
      loadAppsData(userId, setAllApps, setUserEntitlements)
        .catch((error) => { if (appInsights.isInitialized()) appInsights.trackException({ exception: error, properties: { component: 'Settings', location: 'onLoadAppsData' } }); });

    }
  }, [appInsights, userId, tokenLoaded])

  useEffect(() => {
    if (allApps) {
      setAppsObj(JSON.parse(allApps));
    }
  }, [allApps])

  useEffect(() => {
    if (userEntitlements && appsObj) {
      const obj: EntObject = JSON.parse(userEntitlements);
      obj.subscriptions.forEach((sub) => {
        const subobj = JSON.parse(JSON.stringify(sub))
        if (subobj.isFreeTrial) inTrial();
      })
      setEntObj(obj);
    }
  }, [appsObj, inTrial, userEntitlements])

  const [activeLink, setActiveLink] = useState(hasToken ? "newsubscriptions" : "home")

  const navigateToMenu = (key: string) => {
    setActiveLink(key);
  }

  const [usersKey, setRefreshUsers] = useState("initial");
  const [subssKey, setRefreshSubs] = useState("initial");
  const onReloadRequest = (): boolean => {
    if (userId) {
      loadAppsData(userId, setAllApps, setUserEntitlements)
        .then(() => { setRefreshUsers(uuidv4()); setRefreshSubs(uuidv4());  })
        .catch((error) => { if (appInsights.isInitialized()) appInsights.trackException({ exception: error, properties: { component: 'Settings', location: 'onLoadAppsData' } }); });

      return true
    }
    else return false;
  }

  const [navLinkGroups, setNavLinks] = useState<INavLinkGroup[]>(isMobile ? require("./json/settingsmenumobile.json") : require("./json/settingsmenu.json"));
  const [menuKey, setMenuKey] = useState("initial");

  return (
    <div>
      <div style={{ display: 'flex' }}>
        {(navLinkGroups && appsObj && entObj) && (
          <LeftMenu key={menuKey} selectedKey={activeLink} links={navLinkGroups} onLinkClick={navigateToMenu} />
        )}
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: 50 }}>
          {
            (navLinkGroups && (activeLink === "home")) && (
              <>
                {isSignedIn && (<BasePage />)}
              </>
            )
          }
          {
            (navLinkGroups && (activeLink === "newsubscriptions")) && (
              <>
                {(appsObj && entObj) && (<>
                  <Subscriptions key={subssKey} appsObj={appsObj} entObj={entObj} showNewOnly={true} currentUser={userId} tid={tid} theme={theme} themeName={isDarkTheme ? "bitDarkTheme" : "bitLightTheme"} onReloadRequest={onReloadRequest} />
                </>)}
              </>
            )
          }
          {
            (navLinkGroups && (activeLink === "subscriptions")) && (
              <>
                {(appsObj && entObj) && (<>
                  <Subscriptions key={subssKey} appsObj={appsObj} entObj={entObj} showNewOnly={false} currentUser={userId} tid={tid} theme={theme} themeName={isDarkTheme ? "bitDarkTheme" : "bitLightTheme"} onReloadRequest={onReloadRequest} />
                </>)}
              </>
            )
          }
          {
            (navLinkGroups && (activeLink === "help")) && (
              <>
                {(appsObj && entObj) && (<>
                  <Help uid={userId as string} tid={tid} sid={"commerce"} theme={theme} themeName={isDarkTheme ? "bitDarkTheme" : "bitLightTheme"} />
                </>)}
              </>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default Settings;
