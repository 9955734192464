import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { Toggle } from "@fluentui/react";
import { Login } from "@microsoft/mgt-react";
import { bitLightTheme, bitDarkTheme } from "../App"
import { isMobile } from 'react-device-detect';

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props 
 */
export const PageLayout = (props) => {

    const [themeChecked, setThemeChecked] = React.useState(false);

    function _onChange(ev, checked) {
        props.setTheme(checked ? bitLightTheme : bitDarkTheme);
        setThemeChecked(checked);
    }

    return (
        <>
            <div style={{ width: '100%', background: themeChecked ? bitLightTheme.palette.neutralLighter : bitDarkTheme.palette.neutralLighter }}>
                <div style={{ marginLeft: '10px', marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                    {!isMobile && (<img
                        src={themeChecked ? "bitlogoblack.svg" : "bitlogo.svg"}
                        alt="BitFractal Logo"
                        className="align-top d-inline-block"
                        height="45"
                    />)}
                    {isMobile && (<img
                        src="cube.png"
                        alt="BitFractal Logo"
                        className="align-top d-inline-block"
                        height="45"
                    />)}
                    {!isMobile && (<div style={{ display: 'flex', justifyContent: 'space-around', marginLeft: 'auto', width: '100%' }}><h2 style={{ justifySelf: 'center', alignSelf: 'center' }}>Subscriptions Management</h2></div>)}
                    {isMobile && (<div style={{ marginLeft: 'auto' }} ></div>)}
                    <div style={{ marginRight: 10, marginLeft: '10px' }} >
                        <Login className={themeChecked ? "mgt-light" : "mgt-dark"} />
                    </div>
                    <Toggle label={<div>Set theme</div>} onText="Light" offText="Dark" onChange={_onChange} />
                </div>
            </div>
            {props.children}
        </>

    );
};
