import * as React from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Image, Icon, mergeStyleSets, MessageBar, MessageBarType, Separator, Theme, Spinner, SpinnerSize } from '@fluentui/react';
import { createRef, useState } from 'react';
import { People, Person, PersonCardInteraction, PersonViewType } from "@microsoft/mgt-react";
import { Panel } from '@fluentui/react/lib/Panel';
import { useBoolean } from '@fluentui/react-hooks';
import { SubsUsersTable } from './SubsUsersTable';
import { AppObject } from './AppCard';
import { callFunction, loadContactData } from '../lib/Helpers';
import ".././styles/Settings_Subscriptions.css"
import { defaultTheme, panelDarkTheme } from '../App';

type SubscriptionCardProps = {
  subscription?: SubscriptionObject,
  apps?: AppObject[],
  currentUser: string,
  tid: string,
  theme?: Theme,
  themeName?: string,
  onReloadRequest: React.Dispatch<void>,
};

export type EntitlementsObject = {
  appid: string,
  type: string,
  unit: string,
  value: string,
}

export type PlanObject = {
  planId: string,
  displayName: string,
  isPrivate: boolean,
  entitlements: EntitlementsObject[],
}

export type TermObject = {
  startDate: string,
  endDate: string,
  termUnit: string,
}

export type SubscriptionObject = {
  id: string,
  name: string,
  img: string,
  publisherId: string,
  planId: PlanObject,
  quantity: string,
  beneficiary: string,
  isFreeTrial: boolean,
  isTest: boolean,
  saasSubscriptionStatus: string,
  term: TermObject,
  adminUsers: string[],
  users: string[]
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({ subscription, apps, currentUser, tid, theme, themeName, onReloadRequest }) => {
  const appInsights = useAppInsightsContext();
  const cardContainer = mergeStyleSets({
    backgroundColor: theme?.palette.neutralLighter,
    borderTop: '2px solid #FD6262',
    borderRadius: '5px',
    width: '100%',
    padding: '5px',
  });

  const container = {
    margin: '10px 5px 10px 5px',
    width: '100%',
  }

  const [savedAdmins, setSavedAdmins] = useState<string[]>(subscription?.adminUsers as string[]);
  const [savedUsers, setSavedUsers] = useState<string[]>(subscription?.users as string[]);
  const [admins, setAdmins] = useState<string[]>(subscription?.adminUsers as string[]);
  const [users, setUsers] = useState<string[]>(subscription?.users as string[]);
  const [toNotifyUsers, setNotifyUsers] = useState<string[]>([]);
  const userPeopleRef = createRef();

  const userIsAdmin: boolean = (subscription?.adminUsers.find((value) => value === currentUser) ? true : false)
  const userIsOwner: boolean = (subscription?.beneficiary === currentUser ? true : false)

  const onPanelCloseClicked = () => {
    setAdmins(savedAdmins);
    setUsers(savedUsers);
    dismissPanel();
  }

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [savingError, setSavingError] = useState<string | undefined>(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const onRenderFooterContent = React.useCallback(
    () => {
      const onSaveClicked = () => {

        if (isDirty) {
          setIsSaving(true);
          loadContactData(users).then((conts) => {
            const data: any =
            {
              subid: subscription?.id,
              updateType: "multiple",
              users: users,
              adminUsers: admins,
              contacts: conts.map((value) => {
                return (
                  {
                    name: value.displayName,
                    emailId: value.mail,
                    objectId: value.id,
                    tenantId: tid
                  })
              })

            }
            callFunction(true, "patchSubscriptionUsers?{0}", JSON.stringify(data))
              .then((data) => {
                onReloadRequest();
                setSavedAdmins(admins);
                setSavedUsers(users);
                setSavingError(undefined);
                setIsSaving(false);
                dismissPanel();
              })
              .catch((error) => { if (appInsights.isInitialized()) appInsights.trackException({ exception: error, properties: { component: 'SubscriptionCard', location: 'onPatchSubscriptionUsers' } }); setSavingError(error); setIsSaving(false); dismissPanel(); });
          })
            .catch((error) => { if (appInsights.isInitialized()) appInsights.trackException({ exception: error, properties: { component: 'SubscriptionCard', location: 'onLoadContactData' } }); });
        }
        else {
          dismissPanel();
        }
      }

      const onCancelClicked = () => {
        setAdmins(savedAdmins);
        setUsers(savedUsers);
        dismissPanel();
      }

      return (
        <div>
          <div style={{ display: 'flex' }}>
            <button onClick={onSaveClicked} className="bitbutton" >Save</button>
            {isSaving && (<Spinner size={SpinnerSize.small} />)}
            <button onClick={onCancelClicked} className="bitbuttoncancel">Cancel</button>
          </div>
        </div>)
    },
    [admins, appInsights, dismissPanel, isDirty, isSaving, onReloadRequest, savedAdmins, savedUsers, subscription?.id, tid, users],
  );

  const [isActivating, setIsActivating] = useState(false);
  function activateSubscription(subid: string, planid: string, quantity: string) {
    setIsActivating(true);
    callFunction(true, "activateSubscription?{0}&subid=" + subid + "&planid=" + planid + "&quantity=" + quantity)
      .catch((error) => { alert("Unable to activate this subscription. Please try again later. If the problem persist, please contact support"); if (appInsights) appInsights.trackException({ exception: error, properties: { component: 'App', location: 'onActivateSubscription' } }); })
      .then(() => { onReloadRequest(); })
      .finally(() => { setIsActivating(false); })

  }

  return (
    <div style={container}>
      {subscription?.isFreeTrial && (<MessageBar hidden={!subscription?.isFreeTrial} messageBarType={MessageBarType.info}
        isMultiline={false}>This is a trial subscription. Trials are limited to a single user during the trial period.</MessageBar>)}
      <div aria-roledescription="Subscription card" style={cardContainer} key={subscription?.id} title={subscription?.name}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Image
              style={{ height: '43px', width: '31px' }}
              src={subscription?.img}
            />
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px' }}>
              <label style={{ fontWeight: 'bold' }}>{subscription?.planId.displayName}</label>
              <label style={{ fontWeight: 'normal' }}>{subscription?.name}</label>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5 }} >
              <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Expires On </label>
              <label style={{ fontSize: 8, fontWeight: 'lighter' }}>YYYY-MM-DD</label>
              <label style={{ fontWeight: 'normal' }}>{subscription?.term.endDate}</label>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 5 }} >
              <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Owner</label>
              <Person className={themeName === 'bitDarkTheme' ? 'mgt-dark' : ''} userId={subscription?.beneficiary} showPresence view={PersonViewType.avatar} personCardInteraction={PersonCardInteraction.none} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
              <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>{(subscription?.saasSubscriptionStatus.trim() === "Subscribed") ? "Active" : "Pending"}</label>
              {/* {subscription?.isFreeTrial && (subscription?.saasSubscriptionStatus.trim() !== "PendingFulfillmentStart") && (<button hidden={!userIsOwner} className='bitbuttonsmall' >Upgrade</button>)} */}
              {(subscription?.saasSubscriptionStatus.trim() === "PendingFulfillmentStart") && (<button hidden={!userIsOwner} disabled={isActivating} className='bitbuttonsmall' onClick={() => { appInsights.trackEvent({ name: "Activate Clicked", properties: { subsName: subscription?.id } }); activateSubscription(subscription?.id, subscription?.planId.planId, subscription?.quantity) }} >{isActivating && (<Spinner size={SpinnerSize.small} />)}{isActivating ? "Activating" : "Activate"}</button>)}

            </div>
          </div>
        </div>
        <div style={{ margin: '0px 5px 0px 5px' }}><Separator styles={{ root: { padding: '0px' } }} theme={defaultTheme} /></div>
        {savingError && (<MessageBar messageBarType={MessageBarType.error}
          isMultiline={false}>{"Unable to save user changes to the database. Try again later - " + savingError}</MessageBar>
        )}
        <label style={{ fontWeight: 'bold', marginBottom: '2px' }}>Subscription Apps and Entitlements:</label>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', marginBottom: 5 }}>
          {subscription?.planId.entitlements.map((ent) => {
            return (<div key={ent.appid + ent.type} style={{ margin: 10 }}><div style={{ display: 'flex', alignItems: 'center' }} >{apps && (<><Icon iconName={apps.find((value) => value.id === ent.appid)?.iconName} style={{ marginRight: 4, color: "#FD6262" }} /><label style={{ fontWeight: 'bold' }}>{apps.find((value) => value.id === ent.appid)?.name}</label></>)}</div>
              <div style={{ display: 'flex' }} ><label>{ent.type + ": "}</label><label>{ent.value}</label></div></div>)
          })}
        </div>
        <label style={{ fontWeight: 'bold', marginBottom: '2px' }}>Subscription Users:</label>
        <div style={{ display: 'flex', marginTop: '0px', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ fontWeight: 'normal', marginBottom: '5px' }}>Add/Remove and configure users for this subscription</label>
          </div>
          <button hidden={!userIsAdmin || subscription?.isFreeTrial} className='bitbuttonsmall' style={{ height: '35px', alignSelf: 'center' }} onClick={openPanel}>Manage</button>
        </div>
        <div>
          <label>Users:</label>
          <People className={themeName === 'bitDarkTheme' ? 'mgt-dark' : ''} ref={userPeopleRef} showPresence showMax={25} userIds={users} personCardInteraction={PersonCardInteraction.none} ></People>
        </div>
        <div style={{ display: 'flex', marginTop: '0px', justifyContent: 'space-between' }}>
          <div>
            <label>Admins:</label>
            <People className={themeName === 'bitDarkTheme' ? 'mgt-dark' : ''} showMax={25} showPresence userIds={admins} personCardInteraction={PersonCardInteraction.none}></People>
          </div>
        </div>
        <div style={{ margin: '0px 5px 0px 5px' }}><Separator styles={{ root: { padding: '0px' } }} theme={defaultTheme} /></div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Subscription Licenses:</label>
          <label style={{ fontWeight: 'bold', marginBottom: '5px' }} hidden={!userIsOwner || subscription?.isFreeTrial}>Add/Remove licenses for this subscription. NOTE: License quantity changes are processed by Microsoft and can impact monthly costs</label>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <label style={{ fontWeight: 'normal', marginBottom: '5px' }}>{`${subscription?.users.length} licenses of ${subscription?.quantity} assigned`}</label>
          <div style={{ display: 'flex', height: '35px' }}>
            {/* <button hidden={!userIsOwner || subscription?.isFreeTrial} id='popbuttonsave' onClick={() => { appInsights.trackEvent({ name: "Change Licenses Clicked", properties: { subsName: subscription?.id } }); }} className='bitbuttonsmall'>Change</button> */}
          </div>
        </div>
      </div>
      <Panel
        isOpen={isOpen}
        theme={themeName === "bitDarkTheme" ? panelDarkTheme : theme}
        onDismiss={onPanelCloseClicked}
        headerText="Manage Users"
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}>
        <SubsUsersTable users={users as string[]} admins={admins as string[]} owner={subscription?.beneficiary as string} maxUsers={+(subscription?.quantity as string)} theme={theme} themeName={themeName} onAdminsChanged={setAdmins} onUsersChanged={setUsers} onIsDirty={setIsDirty} onToNotifyUsersChanged={setNotifyUsers} />
      </Panel>
    </div >
  );
};

export default SubscriptionCard;


