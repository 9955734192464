import React from 'react'
import '../styles/MyApps_BasePage.css'

const BasePage = () => {
  return (
    <div id='basepagecontainer'>
      <p />
      <img id="picture" alt="No menu item selected" src="defaultCommunication.svg" />
      <h3 id="message">No subscriptions available to show</h3>
    </div>
  )
}

export default BasePage
