import { mergeStyleSets, Separator, Theme } from '@fluentui/react';
import React from 'react'
import '../styles/NoApps.css'
import { Login } from '@microsoft/mgt-react';

type NoAppsProps = {
    theme?: Theme,
    isDarkTheme?: boolean,
};

const NoApps: React.FC<NoAppsProps> = ({ theme, isDarkTheme }) => {

    const cardContainer = mergeStyleSets({
        backgroundColor: theme?.palette.neutralLighter,
        borderTop: '2px solid #FD6262',
        borderRadius: '5px',
        width: '100%',
    });

    return (
        <div id='noappscontainer'>
            <div aria-roledescription="Welcome card" style={cardContainer} key="welcomecard" title="Welcome">
                <div style={{ display: 'flex', justifyContent: 'center' }} >
                    <h3>Welcome to BitFractal</h3>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    <label>On this page you will be able to activate your new subscription and to manage existing ones, assigning users, admins and adjusting quantities</label>
                    <label id="message" style={{ marginBottom: 5 }}>To get started please sign in with your company account:</label>
                    <Login className={isDarkTheme ? "mgt-dark" : "mgt-light"} />
                </div>
            </div>
        </div>
    )
}

export default NoApps;