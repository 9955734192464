import React, { useEffect, useState } from 'react'
import "../styles/Settings_Subscriptions.css"
import SubscriptionCard from '../widgets/SubscriptionCard';
import { AppObject } from '../widgets/AppCard';
import NoApps from '../widgets/NoApps';
import { Theme } from '@fluentui/react'; 
import BasePage from '../widgets/BasePage';

type EntObject = {
  apps: string[],
  subscriptions: string[],
}

type SubscriptionProps = {
  appsObj: AppObject[],
  entObj: EntObject,
  currentUser: string,
  tid: string,
  theme?: Theme,
  themeName?: string,
  showNewOnly?: boolean,
  onReloadRequest: React.Dispatch<void>,
}

const Subscriptions: React.FC<SubscriptionProps> = (props: SubscriptionProps) => {

  const [hasActiveApps, setActiveApps] = useState(false);
  const [hasPendingApps, setPendingApps] = useState(false);

  useEffect(() => {
    props.entObj?.subscriptions?.forEach(sub => {
      const obj = JSON.parse(JSON.stringify(sub));
      if(obj.saasSubscriptionStatus.trim() === "PendingFulfillmentStart") setPendingApps(true);
      setActiveApps(true);
    });
    console.log("RENATO ON USE EFFECT");
  }, [props.entObj?.subscriptions])
  
  return (
    <div id='setsubscontainer' >
      <h3>Subscriptions Management</h3>
      <p />
      {props.showNewOnly && (<label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Subscriptions can only be used after activation. Click the Activate button to enable your subscription.</label>)}
      <div id='setsubinternalcontainer'>
        {props.appsObj && props.entObj && (
          props.entObj?.subscriptions?.map((sub) => {
            const obj = JSON.parse(JSON.stringify(sub));
            if(!props.showNewOnly) return (<SubscriptionCard subscription={obj} key={obj?.id} apps={props.appsObj} currentUser={props.currentUser} tid={props.tid} theme={props.theme} themeName={props.themeName} onReloadRequest={props.onReloadRequest}/>)
            else if (obj.saasSubscriptionStatus.trim() === "PendingFulfillmentStart") return (<SubscriptionCard subscription={obj} key={obj?.id} apps={props.appsObj} currentUser={props.currentUser} tid={props.tid} theme={props.theme} themeName={props.themeName} onReloadRequest={props.onReloadRequest}/>)
            else return (<></>);
          })
        )}
         {props.showNewOnly && !hasPendingApps && (<BasePage/>)}
         {!props.showNewOnly && !hasActiveApps && (<BasePage/>)}
      </div>
    </div>
  )
}

export default Subscriptions
