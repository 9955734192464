import * as React from 'react';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { Icon, MessageBar, MessageBarType, SearchBox, Theme, ThemeProvider, TooltipHost } from '@fluentui/react';
import { PeoplePicker, PeoplePickerProps, Person, PersonCardInteraction, PersonViewType } from '@microsoft/mgt-react';
import { v4 as uuidv4 } from 'uuid';
import { bitLightTheme, defaultTheme, panelDarkTheme } from '../App';
import { GetUserDataFromGraph } from '../lib/Helpers';


const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
});

export interface ISubsUsersTableState {
  columns: IColumn[];
  items: IDocument[];
  selectionDetails: string;
  isModalSelection: boolean;
  isCompactMode: boolean;
  announcedMessage?: string;
  isDirty: boolean;
  isAboveLimit: boolean;
  selectedPeople?: string[];
  refreshPerson: string;
}

export interface IDocument {
  key: string;
  name: string;
  value: string;
  isAdmin: boolean;
  isOwner: boolean;
}

type Props = {
  users: string[],
  admins: string[],
  owner: string,
  maxUsers: number,
  theme?: Theme,
  themeName?: string,
  onAdminsChanged: React.Dispatch<React.SetStateAction<string[]>>,
  onUsersChanged: React.Dispatch<React.SetStateAction<string[]>>,
  onIsDirty: React.Dispatch<React.SetStateAction<boolean>>,
  onToNotifyUsersChanged: React.Dispatch<React.SetStateAction<string[]>>,
}

export class SubsUsersTable extends React.Component<Props, ISubsUsersTableState> {
  private _selection: Selection;
  private _allItems: IDocument[];
  private _ppickerRef;
  private _isPersonLoaded: Record<string, boolean>;
  private _toNotifyUsers: string[];
  private _theme: Theme;
  private _themeName: string;

  constructor(props: Props) {
    super(props);

    this._allItems = _generateDocuments(props);
    this._ppickerRef = React.createRef<PeoplePickerProps>();
    this._isPersonLoaded = {};
    this._toNotifyUsers = [];
    this._theme = props.theme as Theme;
    this._themeName = props.themeName as string;

    const columns: IColumn[] = [
      {
        key: 'column1',
        name: 'User',
        fieldName: 'name',
        minWidth: 160,
        maxWidth: 160,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        onRender: (item: IDocument) => {
          return (
            <div>
              <Person className={this._themeName === 'bitDarkTheme' ? 'mgt-dark mgt-person' : 'mgt-person'} onLoad={this._onLoadedPerson(item)} userId={item.key} view={PersonViewType.twolines} showPresence personCardInteraction={PersonCardInteraction.none} line2Property='email' />
            </div>
          )
        },
        isPadded: false,
      },
      {
        key: 'column2',
        name: 'Admin',
        fieldName: 'isAdmin',
        minWidth: 45,
        maxWidth: 45,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'boolean',
        onRender: (item: IDocument) => {
          return <Toggle id={item.key} hidden={item.isOwner} checked={item.isAdmin} onChange={() => { this._onChangeAdminMode(item) }} />;
        },
      },
      {
        key: 'column3',
        name: '',
        className: classNames.fileIconCell,
        iconClassName: classNames.fileIconHeaderIcon,
        ariaLabel: 'Column operations for Delete, Press to sort on Delete',
        iconName: 'Delete',
        isIconOnly: true,
        fieldName: 'delete',
        minWidth: 16,
        maxWidth: 16,
        onRender: (item: IDocument) => (
          <TooltipHost content="Unassign User">
            {!item.isOwner && (<Icon iconName="Cancel" style={{cursor: 'pointer'}} onClick={() => { this._onClickUnassignUser(item) }} />)}
          </TooltipHost>
        ),
      },
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.state = {
      items: this._allItems,
      columns: columns,
      selectionDetails: this._getSelectionDetails(),
      isModalSelection: false,
      isCompactMode: false,
      announcedMessage: undefined,
      isDirty: false,
      isAboveLimit: false,
      selectedPeople: undefined,
      refreshPerson: "initial",
    };
  }

  public render() {
    const { columns, isCompactMode, items, isDirty, isAboveLimit } = this.state;

    return (
      <div>
        {isDirty && (<MessageBar
          messageBarType={MessageBarType.warning}
          isMultiline={false}>Active changes, click Save to confirm</MessageBar>)}
        <PeoplePicker id='peoplepicker'  ref={this._ppickerRef} className={this._themeName === 'bitDarkTheme' ? 'mgt-dark' : ''} selectionChanged={this._onPeopleSelectionChanged}></PeoplePicker>
        {isAboveLimit && (<MessageBar messageBarType={MessageBarType.error}
          isMultiline={false}>{`You can only add ${this.props.maxUsers as number - items.length} more users`}</MessageBar>)}
        <div style={{ display: 'flex', marginTop: 5, justifyContent: 'space-between' }}>
          <label style={{ color: this._theme.palette.black }}>{`${this._allItems.length} licenses out of ${this.props.maxUsers} assigned`}</label>
          <button className='bitbuttonsmall' onClick={this._onclickAddUsers}>Add</button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
          <SearchBox styles={{ root: { width: 200 } }} placeholder="Search" onChange={(event, data) => {
            this.setState({ items: data ? this._allItems.filter(i => i.name.toLowerCase().indexOf(data) > -1) : this._allItems, });
          }} />
        </div>
        <div>
          <ThemeProvider applyTo='element' theme={this._themeName === "bitDarkTheme" ? panelDarkTheme : this._theme}>
            <DetailsList
              key={this.state.refreshPerson}
              styles={{ headerWrapper: { borderTop: "1px solid #FD6262", marginTop: 0, } }}
              items={items}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              getKey={this._getKey}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onItemInvoked={this._onItemInvoked}
            />
          </ThemeProvider>
        </div>
      </div>
    );
  }

  public componentDidUpdate(previousProps: any, previousState: ISubsUsersTableState) {
    if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
      this._selection.setAllSelected(false);
    }
  }

  public componentDidMount() {

  }

  private _getKey(item: any, index?: number): string {
    return item.key;
  }

  private _onPeopleSelectionChanged = (e: Event): void => {
    this.setState({ selectedPeople: (e as CustomEvent).detail });
  }

  private _onLoadedPerson = (item: IDocument): undefined => {
    if (!this._isPersonLoaded[item.key]) {
      this._isPersonLoaded[item.key] = true;
      this.setState({ refreshPerson: uuidv4() });
    }
    return undefined;
  }

  private _onChangeAdminMode = (item: IDocument): void => {
    const { items } = this.state;
    items[items.findIndex((value) => value.key === item.key)].isAdmin = !items[items.findIndex((value) => value.key === item.key)].isAdmin;

    const newAdmins: string[] = items.filter((value) => value.isAdmin === true).map((e) => e.key);
    this.props.onAdminsChanged(newAdmins);
    this.props.onIsDirty(true);
    this.setState({ items: items.slice(0), isDirty: true });
  };

  private _onClickUnassignUser = (item: IDocument): void => {
    const { items } = this.state;
    this._allItems = items.filter(i => i.key !== item.key);
    this._toNotifyUsers = this._toNotifyUsers.filter(i => i !== item.key);
    this.props.onToNotifyUsersChanged(this._toNotifyUsers);
    if (item.isAdmin) this._onChangeAdminMode(item);

    const newUsers: string[] = items.filter(i => i.key !== item.key).map((e) => e.key);
    this.props.onUsersChanged(newUsers);
    this.props.onIsDirty(true);
    this.setState({ items: items.filter(i => i.key !== item.key), isDirty: true });
  };

  private _onclickAddUsers = (ev: React.MouseEvent<HTMLButtonElement>) => {
    const { items, selectedPeople } = this.state;
    if (selectedPeople) {
      if (selectedPeople.length > (this.props.maxUsers - items.length)) {
        this.setState({ isAboveLimit: true });
      }
      else {
        selectedPeople.forEach(element => {
          const people = JSON.parse(JSON.stringify(element));
          if (!items.find((value) => value.key === people.id)) {
            items.push({
              key: people.id,
              name: people.displayName,
              value: people.id,
              isAdmin: false,
              isOwner: false,
            })
          }

          if (!this._toNotifyUsers.find((value) => value === people.id)) this._toNotifyUsers.push(people.id);
        });

        if (this._ppickerRef.current) this._ppickerRef.current.selectedPeople = [];
        this.props.onUsersChanged(items.map((e) => e.key));
        this.props.onToNotifyUsersChanged(this._toNotifyUsers);
        this.props.onIsDirty(true);
        this.setState({ items: items.slice(0), isDirty: true, isAboveLimit: false });
      }
    }
  }

  private _onChangeCompactMode = (ev: React.MouseEvent<HTMLElement>, checked: boolean | undefined): void => {
    this.setState({ isCompactMode: checked as boolean });
  };

  private _onChangeModalSelection = (ev: React.MouseEvent<HTMLElement>, checked: boolean | undefined): void => {
    this.setState({ isModalSelection: checked as boolean });
  };

  private _onItemInvoked(item: any): void {

  }

  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();

    switch (selectionCount) {
      case 0:
        return 'No items selected';
      case 1:
        return '1 item selected: ' + (this._selection.getSelection()[0] as IDocument).name;
      default:
        return `${selectionCount} items selected`;
    }
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? 'descending' : 'ascending'
            }`,
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}



function _generateDocuments(props: Props) {
  const items: IDocument[] = [];
  props.users.forEach(user => {
    items.push({
      key: user,
      name: user,
      value: user,
      isAdmin: (props?.admins.find((usr) => usr === user) ? true : false),
      isOwner: (user === props.owner ? true : false)
    });

    GetUserDataFromGraph(user).then((data) => {
      items[items.findIndex((value) => value.key === user)].name = data.displayName;
    });

  });
  return items;
}
