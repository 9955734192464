import React from "react";
import { Button, Loader } from "@fluentui/react-northstar";
import * as axios from "axios";
import { useData } from "./useData";
import { Providers } from '@microsoft/mgt-react';

export const random = (min, max) =>
    Math.round(Math.random() * (max - min) + min);


export const loadAppsData = async (uid, allAppsHook, entitlementsHook) => {
    try {
        if (uid) {
            const resApps = await callFunction(false, "getAllApps?{0}");
            await allAppsHook(JSON.stringify(resApps));

            const res = await callFunction(true, "getUserApps?{0}");
            await entitlementsHook(JSON.stringify(res));
        }
    }
    catch (err) {
        throw err;
    }
}

export const loadReadinessData = async (uid, readinessDataHook) => {
    try {
        if (uid) {

            const res = await callFunction(false, "getReadinessContent?{0}");
            readinessDataHook(JSON.stringify(res));

        }
    }
    catch (err) {
        throw err;
    }
}

export async function getSettings() {

    const response = await fetch('/api/getSettings');
    return await response.json();
}

export async function callFunction(useCredentials, functionName, params) {
    try {
        var response;
        const { funcEndPoint, funcAPIKey } = await getSettings();

        const apiConfig = funcEndPoint;
        const apiKey = funcAPIKey;

        if (useCredentials) {
            const accessToken = await Providers.globalProvider.getAccessToken();


            if (params) {
                response = await axios.default.post(apiConfig + "/api/" + functionName?.replace("{0}", "code=" + apiKey), params,
                    {
                        headers: {
                            authorization: accessToken,
                        },
                    });
                return response.data;
            } else {
                response = await axios.default.get(apiConfig + "/api/" + functionName?.replace("{0}", "code=" + apiKey), {
                    headers: {
                        authorization: accessToken,
                    },
                });
                return response.data;
            }

        }
        else {

            if (params) {
                response = await axios.default.post(apiConfig + "/api/" + functionName?.replace("{0}", "code=" + apiKey), params, {});
            }
            else {
                response = await axios.default.get(apiConfig + "/api/" + functionName?.replace("{0}", "code=" + apiKey), {});
                return response.data;
            }
        }
    } catch (err) {
        if (axios.default.isAxiosError(err)) {
            let funcErrorMsg = "";

            if (err?.response?.status === 404) {
                funcErrorMsg = `Azure Function not found`;
            } else if (err.message === "Network Error") {
                funcErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    funcErrorMsg += `make sure to start Azure Function locally first before running this App`;
                } else {
                    funcErrorMsg += `make sure to provision and deploy the Azure Function `;
                }
            } else {
                funcErrorMsg = err.message;
                if (err.response?.data?.error) {
                    funcErrorMsg += ": " + err.response.data.error;
                }
            }

            throw new Error(funcErrorMsg);
        }
        throw err;
    }
}

export function FunctionsCaller(props) {
    const { functionName, useCredentials, isVisual, callbackHook } = {
        functionName: "",
        useCredentials: true,
        ...props,
    };

    const { loading, data, error, reload } = useData(callFunction, {
        auto: false,
        useCredentials: useCredentials,
        functionName: functionName,
    });

    if (isVisual) {
        return (
            <div>
                <Button primary content="Call Azure Function" disabled={loading} onClick={reload} />
                {loading && (
                    <pre className="fixed">
                        {" "}
                        <Loader />{" "}
                    </pre>
                )}
                {!loading && !!data && !error && <pre className="fixed">{JSON.stringify(data, null, 2)}</pre>}
                {!loading && !data && !error && <pre className="fixed"></pre>}
                {!loading && !!error && <div className="error fixed">{error.toString()}</div>}
            </div>
        );
    }
    else {
        if (callbackHook) callbackHook(data);
    }
}

export async function loadContactData(users) {

    let contacts = [];
    let proms = [];
    users.forEach(element => {
        proms.push(GetUserDataFromGraph(element).then((value) => contacts.push(value)));
    });
    await Promise.allSettled(proms);
    return contacts;
}

export async function GetUserDataFromGraph(uid) {
    try {
        let provider = Providers.globalProvider;
        if (provider) {
            let graphClient = provider.graph.client;
            const profile = await graphClient.api("users/" + uid).get();
            return profile;
        }
    }
    catch (err) {
        throw err;
    }
}



