import React, { useEffect, useState } from "react";
import { PageLayout } from "./widgets/PageLayout";
import { createTheme, initializeIcons, ThemeProvider } from '@fluentui/react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import { callFunction } from './lib/Helpers';
import { Providers, ProviderState } from '@microsoft/mgt-react';
import "./styles/App.css";
import Settings from "./Settings";
import NoApps from "./widgets/NoApps";

initializeIcons();

const browserHistory = createBrowserHistory();
var reactPlugin = new ReactPlugin();

export const bitLightTheme = createTheme({
  palette: {
    themePrimary: '#fd6262',
    themeLighterAlt: '#fff9f9',
    themeLighter: '#ffe6e6',
    themeLight: '#fed0d0',
    themeTertiary: '#fda1a1',
    themeSecondary: '#fd7575',
    themeDarkAlt: '#e35959',
    themeDark: '#c04b4b',
    themeDarker: '#8d3737',
    neutralLighterAlt: '#eeeeee',
    neutralLighter: '#ffffff',
    neutralLight: '#e1e1e1',
    neutralQuaternaryAlt: '#d1d1d1',
    neutralQuaternary: '#c8c8c8',
    neutralTertiaryAlt: '#c0c0c0',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#f5f5f5',
  }
});

export const bitDarkTheme = createTheme({
  palette: {
    themePrimary: '#fd6262',
    themeLighterAlt: '#0a0404',
    themeLighter: '#281010',
    themeLight: '#4c1e1e',
    themeTertiary: '#973b3b',
    themeSecondary: '#de5757',
    themeDarkAlt: '#fd7272',
    themeDark: '#fd8888',
    themeDarker: '#fea7a7',
    neutralLighterAlt: '#1b1a19',
    neutralLighter: '#424242',
    neutralLight: '#1a1918',
    neutralQuaternaryAlt: '#181716',
    neutralQuaternary: '#171615',
    neutralTertiaryAlt: '#161514',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#2d2d2c',
  }
});

export const panelDarkTheme = createTheme({
  palette: {
    themePrimary: '#fd6262',
    themeLighterAlt: '#0a0404',
    themeLighter: '#281010',
    themeLight: '#4c1e1e',
    themeTertiary: '#973b3b',
    themeSecondary: '#de5757',
    themeDarkAlt: '#fd7272',
    themeDark: '#fd8888',
    themeDarker: '#fea7a7',
    neutralLighterAlt: '#1b1a19',
    neutralLighter: '#404040',
    neutralLight: '#1a1918',
    neutralQuaternaryAlt: '#181716',
    neutralQuaternary: '#171615',
    neutralTertiaryAlt: '#161514',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#424242',
  }
});

export const defaultTheme = createTheme({
  palette: {
    themePrimary: '#0078d4',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#71afe5',
    themeSecondary: '#2b88d8',
    themeDarkAlt: '#106ebe',
    themeDark: '#005a9e',
    themeDarker: '#004578',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  }
});


export default function App() {
  const [activeTheme, setActiveTheme] = useState(bitDarkTheme);
  const [appSettings, setAppSettings] = useState({});

  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  
  const [isSignedIn, setSignedIn] = useState<boolean>(Providers.globalProvider.state === ProviderState.SignedIn ? true : false);
  Providers.onProviderUpdated(() => {
    if (Providers.globalProvider.state === ProviderState.SignedIn) {
      setSignedIn(true)
    }
    else if (Providers.globalProvider.state === ProviderState.SignedOut) {
      setSignedIn(false)
    }
  })  
  
  useEffect(() => {
    var appInsights: ApplicationInsights;
    fetch('/api/getSettings').then((value) => {
      value.json().then((value) => {

        setAppSettings(value);

        const { appInsightsKey } = value;

        appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: appInsightsKey,
            enableAutoRouteTracking: true,
            extensions: [reactPlugin],
            extensionConfig: {
              [reactPlugin.identifier]: { history: browserHistory }
            }
          }
        });
        if (appInsightsKey !== "") appInsights.loadAppInsights();

      })
    });
  }, [])

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <ThemeProvider applyTo='body' theme={activeTheme}>
        <PageLayout setTheme={setActiveTheme}>
          <div style={{ marginLeft: '20px' }}>
            {!isSignedIn && (<div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: 50 }}><NoApps theme={activeTheme} isDarkTheme={activeTheme === bitDarkTheme ? true : false} /></div>)}
            {isSignedIn && (<Settings theme={activeTheme} isDarkTheme={activeTheme === bitDarkTheme ? true : false} hasToken={token ? true : false} />)}
          </div>
        </PageLayout>
      </ThemeProvider>
    </AppInsightsContext.Provider>
  );
}

