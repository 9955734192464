import { mergeStyleSets, Separator, Theme } from '@fluentui/react';
import React, { createRef } from 'react'
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { isMobile } from 'react-device-detect';
import { callFunction } from '../lib/Helpers';
import "../styles/Settings_Help.css"
import { defaultTheme } from '../App';

type HelpProps = {
  uid: string,
  tid: string,
  sid: string,
  theme?: Theme,
  themeName?: string,
}

const Help: React.FC<HelpProps> = ({ uid, tid, sid, theme, themeName }) => {
  const appInsights = useAppInsightsContext();
  const cardContainer = mergeStyleSets({
    backgroundColor: theme?.palette.neutralLighter,
    borderTop: '2px solid #FD6262',
    borderRadius: '5px',
    marginLeft: '5px',
    margingRight: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
  });

  const container = {
    margin: '10 5 10 0',
    width: '100%',
  }

  const refSelect = createRef<HTMLSelectElement>();
  const refText = createRef<HTMLTextAreaElement>();
  const refMessage = createRef<HTMLLabelElement>();

  const sendFeedback = (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (refText.current) {
      if (refText.current.value.length > 0) {
        callFunction(true, "sendFeedback?{0}&message=" + refText.current.value + "&type=" + refSelect.current?.selectedOptions.item(0)?.value)
          .catch((error) => { if ( appInsights.isInitialized()) appInsights.trackException({ exception: error, properties: { component: 'Help', location: 'onSendFeedback' } }); });
        refText.current.value = "";
        if (refSelect.current) refSelect.current.selectedIndex = 0;
        if (refMessage.current) refMessage.current.hidden = false;
      }
    }
    ev.preventDefault();
  }

  const onTextChange = () => {
    if (refText.current && refMessage.current) refMessage.current.hidden = true;
  }

  return (
    <div id={!isMobile ? 'helpcontainer' : 'helpcontainermobile'}>
      <h3>Getting Help and Support</h3>
      <div id={!isMobile ? 'internalhelpcontainer' : 'internalhelpcontainermobile'}>
        <div style={container}>
          <div aria-roledescription="Support card" style={cardContainer} key="supportcard" title="Getting help and support">
            <label style={{ marginTop: '5px' }}>All BitFractal Apps are supported by our BitBot. Our virtual assistant can respond to any questions you might
              have about how to use any application, tips and tricks and much more, BitBot can automate processes, offer unique
              capabilities to simplify your day and send messages to our support center, in case it can't help you directly. To get
              started, go to our <span><a href='https://www.bitfractal.com'>Home Page</a></span> or on the BitFract App on Teams, switch to the Chat tab (or, if you're on you phone, click on the bot icon at the top right) and type Help and let BitBot guide you from there.</label>
            <div style={{ margin: '0px 5px 0px 5px' }}><Separator theme={defaultTheme} /></div>
            <h4>Contacting Support via Email</h4>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>If you prefer to reach out directly to our support center, we're here to help, just email us at: <span><a href="mailto:support@bitfractal.com">support@bitfractal.com</a></span></label>
            </div>
            <div style={{ margin: '0px 5px 0px 5px' }}><Separator theme={defaultTheme} /></div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <h4>Share your Ideas and Feedback</h4>
              <label>BitFractal Apps are designed by users for users, and there's no better way to learn and improve our apps then listening
                to you, so please, share your Ideas and Feedback using the form below and help us improve our products and services to better
                serve you:</label>
            </div>
            <form className="ideasForm">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h4 style={{marginTop: '5px'}}>Feedback Type:</h4>
                <select ref={refSelect} id='feedbacktype' className="ideaoptions" name="list" >
                  <option className="ideaoptions">Feedback</option>
                  <option className="ideaoptions">Ideas</option>
                </select>
                <h4>Message:</h4>
                <textarea ref={refText} id='feedbacktext' name="message" onChange={onTextChange} style={{ width: "100%", height: "180", fontSize: 12 }}></textarea>
              </div>
              <button className="bitbuttonsmall" onClick={sendFeedback}>Send Message</button>
              <label ref={refMessage} hidden={true} style={{ fontSize: 12 }} id="messagesent">Thank you for your message. Your feedback is very important to us.</label>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Help
