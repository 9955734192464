import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/styles/index.css";
import App from "./components/App";
import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';

Providers.globalProvider = new Msal2Provider({
    clientId: "8f261936-59e1-44b3-8234-a746c5e4eab7",
    scopes: ["User.Read", "People.Read", "User.ReadBasic.All", "Presence.Read.All",]
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
